import React, { useEffect, useState } from "react"
import { FaRandom, FaSortAlphaDown } from "react-icons/fa"

import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"

import { isSSR } from "../../../components/Script/script"
import {
  Title,
  Columns,
  Column,
  Textarea,
  Button,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"
import tickAudio from "../../../componentForPages/audio/Tick.mp3"
import winAudio from "../../../componentForPages/audio/winner.mp3"

const GoogleAds = React.lazy(() => import("../../../components/ADS/ADS"))
const Popup = React.lazy(() => import("../../../componentForPages/Popup/Popup"))

const navRoadArray = [
  { name: "Generator", link: "/generator/" },
  {
    name: "Wheel Of Names",
    link: "/wheel-of-names/",
  },
]

const seeAlsoArray = [
  "/full-name-generator",
  "/first-name-generator",
  "/flip-a-coin",
  "/font-generator",
  "/quote-generator",
]

function WheelOfNames(props) {
  let [textList, setTextList] = useState(
    "Collin\nHarold\nLucas\nKhari\nJax\nDayton"
  )
  let [textPicked, setTextPicked] = useState("")
  let [loading, setLoading] = useState(false)
  let [show, setShow] = useState(false)

  function addToList(e) {
    setTextList(e.target.value)
  }

  let result = textList.split("\n")
  let options = result.filter(e => e)

  let startAngle = 0
  let arc = Math.PI / (options.length / 2)
  let spinTimeout = null

  let spinAngleStart = Math.random() * 10 + 10
  // let spinArcStart = 10
  let spinTime = 0
  let spinTimeTotal = 0

  let ctx

  function byte2Hex(n) {
    let nybHexString = "0123456789ABCDEF"
    return (
      String(nybHexString.substr((n >> 4) & 0x0f, 1)) +
      nybHexString.substr(n & 0x0f, 1)
    )
  }

  function RGB2Color(r, g, b) {
    return "#" + byte2Hex(r) + byte2Hex(g) + byte2Hex(b)
  }

  function getColor(item, maxitem) {
    let phase = 0
    let center = 128
    let width = 127
    let frequency = (Math.PI * 2) / maxitem

    let red = Math.sin(frequency * item + 2 + phase) * width + center
    let green = Math.sin(frequency * item + 0 + phase) * width + center
    let blue = Math.sin(frequency * item + 4 + phase) * width + center

    return RGB2Color(red, green, blue)
  }

  useEffect(() => {
    drawRouletteWheel()
  }, [textList])

  function playSound(sound) {
    if (!isSSR) {
      let audioTick = new Audio(tickAudio)
      let audioWin = new Audio(winAudio)

      if (sound === "tick") {
        pl(audioTick)
      } else {
        pl(audioWin)
      }
      function pl(audio) {
        // Stop and rewind the sound (stops it if already playing).
        audio.pause()
        audio.currentTime = 0
        // Play the sound.
        audio.play()
      }
    }
  }
  let firtNameText

  function drawRouletteWheel() {
    let canvas = document.getElementById("canvas")
    if (canvas.getContext) {
      let outsideRadius = 200
      let textRadius = 160
      let insideRadius = 25 // white space in middel

      let fontSizeForOptionLenght = 17 / options.length
      ctx = canvas.getContext("2d")
      ctx.clearRect(0, 0, 500, 500)
      ctx.strokeStyle = "black"
      ctx.lineWidth = 1
      ctx.font = `bold ${
        fontSizeForOptionLenght > 2
          ? 2
          : fontSizeForOptionLenght < 0.75
          ? 0.75
          : fontSizeForOptionLenght
      }rem Helvetica, Arial`

      for (let i = 0; i < options.length; i++) {
        let angle = startAngle + i * arc
        //ctx.fillStyle = colors[i];
        ctx.fillStyle = getColor(i, options.length)
        ctx.beginPath()
        ctx.arc(250, 250, outsideRadius, angle, angle + arc, false)
        ctx.arc(250, 250, insideRadius, angle + arc, angle, true)
        ctx.stroke()
        ctx.fill()
        ctx.save()
        ctx.shadowOffsetX = -1
        ctx.shadowOffsetY = -1
        ctx.shadowBlur = 0
        // ctx.shadowColor = "rgb(220,220,220)"
        ctx.translate(
          250 + Math.cos(angle + arc / 2) * textRadius,
          250 + Math.sin(angle + arc / 2) * textRadius
        )
        if (options.length > 10) {
          ctx.rotate(angle + arc / 2 + Math.PI / 2 + 80) // +80 to change rotation text
          // ctx.font = "bold 0.5rem Helvetica, Arial"
        } else {
          ctx.rotate(angle + arc / 2 + Math.PI / 2)
        }
        let text = options[i]

        if (ctx.measureText(text).width > 130) {
          ctx.font = "bold 0.75rem Helvetica, Arial"
          // console.log(
          //   `text: ${text},text length: ${text.length},arc: ${arc}, ctx: ${
          //     ctx.measureText(text).width
          //   }`
          // )
        }
        ctx.strokeStyle = "black"
        ctx.lineWidth = 2
        ctx.strokeText(text, -ctx.measureText(text).width / 2, 0)
        ctx.fillStyle = "white"
        ctx.fillText(text, -ctx.measureText(text).width / 2, 0)
        ctx.restore()
      }
      //Arrow
      ctx.fillStyle = "black"
      ctx.beginPath()
      ctx.moveTo(250 - 4, 250 - (outsideRadius + 5))
      ctx.lineTo(250 + 4, 250 - (outsideRadius + 5))
      ctx.lineTo(250 + 4, 250 - (outsideRadius - 5))
      ctx.lineTo(250 + 9, 250 - (outsideRadius - 5))
      ctx.lineTo(250 + 0, 250 - (outsideRadius - 13))
      ctx.lineTo(250 - 9, 250 - (outsideRadius - 5))
      ctx.lineTo(250 - 4, 250 - (outsideRadius - 5))
      ctx.lineTo(250 - 4, 250 - (outsideRadius + 5))
      ctx.fill()
    }
  }

  function spin() {
    if (options.length > 1) {
      spinTime = 0
      spinTimeTotal = Math.random() * 3 + 8 * 1000
      rotateWheel()
    } else {
    }
  }

  function rotateWheel() {
    if (loading === false) {
      setLoading(true)
    }
    let degrees = (startAngle * 180) / Math.PI + 90
    let arcd = (arc * 180) / Math.PI
    let index = Math.floor((360 - (degrees % 360)) / arcd)

    if (firtNameText !== options[index]) {
      if (!loading) {
        firtNameText = options[index]
        playSound("tick")
      }
    }

    spinTime += 10
    if (spinTime >= spinTimeTotal) {
      stopRotateWheel()
      return
    }

    let spinAngle =
      spinAngleStart - easeOut(spinTime, 0, spinAngleStart, spinTimeTotal)
    startAngle += (spinAngle * Math.PI) / 180
    drawRouletteWheel()
    spinTimeout = setTimeout(() => rotateWheel(), 10) //30
  }

  function stopRotateWheel() {
    clearTimeout(spinTimeout)
    let degrees = (startAngle * 180) / Math.PI + 90
    let arcd = (arc * 180) / Math.PI
    let index = Math.floor((360 - (degrees % 360)) / arcd)
    // ctx.save()
    // ctx.font = "white bold 30px Helvetica, Arial"
    let text = options[index]
    // add win text in the middel
    // ctx.fillText(text, 250 - ctx.measureText(text).width / 2, 250 + 10)
    // ctx.restore()
    setTextPicked(text)
    setShow(true)
    playSound()
    setLoading(false)
  }

  function easeOut(t, b, c, d) {
    let ts = (t /= d) * t
    let tc = ts * t
    return b + c * (tc + -3 * ts + 3 * t)
  }

  function shuffle() {
    let currentIndex = options.length,
      temporaryValue,
      randomIndex
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1
      // And swap it with the current element.
      temporaryValue = options[currentIndex]
      options[currentIndex] = options[randomIndex]
      options[randomIndex] = temporaryValue
    }
    setTextList(options.join("\n"))
    // return options
  }
  function sortArray() {
    let arrySort = options.sort()
    setTextList(arrySort.join("\n"))
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Wheel Of Names"
        description="Wheel of names is an online tool that helps you to find a winner or choose between several options by the spin wheel, simple enter names in the Text area and find the winner."
        keywords={[
          "wheel of names, wheel names generator, random names wheel,randomizer names,randomizer, random names, names generator, spin wheel to find winner,wheel of options ",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Wheel Of Names</Title>
        <br />
        <Columns>
          <Column>
            <FlexDiv
              displayDiv="flex"
              justifyContent="center"
              textAlign="center"
            >
              <Button
                value="spin"
                id="spin"
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                onClick={spin}
                disabled={loading ? true : ""}
              >
                Spin
              </Button>
            </FlexDiv>
            <canvas
              id="canvas"
              height="500"
              width="500"
              style={{ width: "100%" }}
            ></canvas>

            <p className="h5">
              {!loading ? `The Winner is ${textPicked}` : ""}
            </p>
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                {show ? (
                  <Popup
                    title="We have a winner!"
                    emoji={textPicked}
                    close={() => setShow(false)}
                  />
                ) : (
                  ""
                )}
              </React.Suspense>
            )}
          </Column>
          <Column>
            <Button
              className="mb-3"
              onClick={shuffle}
              disabled={loading ? true : ""}
            >
              <FaRandom /> <span className="ml-1">Shuffle</span>
            </Button>
            <Button
              className="mb-3"
              onClick={sortArray}
              disabled={loading ? true : ""}
            >
              <FaSortAlphaDown /> <span className="ml-1">Sort</span>
            </Button>
            <Textarea value={textList} onChange={addToList} />

            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>
        <br />
        <h3>Wheel of names</h3>
        <p>
          Wheel of names is an online tool that helps you to find a winner or
          choose between several options by the spin wheel, just enter names in
          the Text area, every name or option should be on a new line then click
          "Spin" to spin the wheel and find the winner.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default WheelOfNames
